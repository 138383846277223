import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";

export const REPORT_ENDPOINTS = {
  reports: `${BASE_URL}api/reports/`,
  reportsV2: `${BASE_URL}api/reports/reports_v2`,
  viewStatus: `${BASE_URL}api/reports/view_status`,
  viewStatusV2: `${BASE_URL}api/reports/view_status_v2`,
  sendMessage: `${BASE_URL}api/reports/send_message`,
  tags: `${BASE_URL}api/reports/reports_tags`,
  reportId: (id) => `${BASE_URL}api/reports/${id}`,
  favorite: (id) => `${BASE_URL}api/reports/favorite/${id}`,
  unread: `${BASE_URL}api/reports/count_unread`,
};

class Report {
  async create(body) {
    var { data, res } = await request({
      url: REPORT_ENDPOINTS.reports,
      method: "POST",
      body,
    });
    return {
      data,
      res,
    };
  }

  async getTags(body) {
    var { data, res } = await request({
      url: REPORT_ENDPOINTS.tags,
      method: "POST",
      body,
    });
    return {
      data,
      res,
    };
  }

  async createCustomReport(body) {
    var { data, res } = await request({
      url: REPORT_ENDPOINTS.sendMessage,
      method: "POST",
      body,
    });
    return {
      data,
      res,
    };
  }

  async getAllV2(body) {
    var { data, res } = await request({
      url: REPORT_ENDPOINTS.reportsV2,
      method: "POST",
      body,
    });
    return {
      data,
      res,
    };
  }

  async viewStatusV2(body) {
    var { data, res } = await request({
      url: REPORT_ENDPOINTS.viewStatusV2,
      method: "POST",
      body,
    });
    return {
      data,
      res,
    };
  }

  async update(body, id) {
    var { data, res } = await request({
      url: REPORT_ENDPOINTS.reportId(id),
      method: "PUT",
      body,
    });
    return {
      data,
      res,
    };
  }

  async getAll() {
    var { data, res } = await request({
      url: REPORT_ENDPOINTS.reports,
      method: "GET",
    });
    return {
      data,
      res,
    };
  }

  async getViewStatus() {
    var { data, res } = await request({
      url: REPORT_ENDPOINTS.viewStatus,
      method: "GET",
    });
    return {
      data,
      res,
    };
  }

  async getById(id) {
    var { data, res } = await request({
      url: REPORT_ENDPOINTS.reportId(id),
      method: "GET",
    });
    return {
      data,
      res,
    };
  }

  async postFavorite(id) {
    var { data, res } = await request({
      url: REPORT_ENDPOINTS.favorite(id),
      method: "PUT",
    });
    return {
      data,
      res,
    };
  }

  async getUnreadMsg() {
    var { data, res } = await request({
      url: REPORT_ENDPOINTS.unread,
      method: "GET",
    });
    return {
      data,
      res,
    };
  }
}

export const ReportService = new Report();
