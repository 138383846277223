import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";
import { ActiveUser } from "@/services/user";

export const TEMPLATES_ENDPOINTS = {
  profillate: `${BASE_URL}api/templates/profillate`,
  check_reuse: `${BASE_URL}api/templates/check_reuse`,
  record: `${BASE_URL}api/templates/record`,
  find: `${BASE_URL}api/templates/find`,
  find_project: `${BASE_URL}api/templates/find_project`,
  custom_columns: `${BASE_URL}api/templates/custom_columns`,
  to_sql: `${BASE_URL}api/data_processing/to_sql`,
  edit_data: `${BASE_URL}api/data_processing/edit_data`,
  delete_template: `${BASE_URL}api/data_processing/delete_template`,
  grant: `${BASE_URL}api/templates/grant`,
  revoke_access: `${BASE_URL}api/templates/revoke_access`,
  parse_detail: (id) => `${BASE_URL}api/templates/parse_columns_detail/${id}`,
  parse: `${BASE_URL}api/templates/parse_columns`,
  parse_v2: `${BASE_URL}api/templates/parse_columns_v2`,
  create_view: (id) => `${BASE_URL}api/templates/create_view/${id}`,
  access_list: (template_id) =>
    `${BASE_URL}api/templates/access_list/${template_id}`,
  get_view: (id) => `${BASE_URL}api/templates/get_view/${id}`,
};

class Templates {
  async profillate({
    tableData,
    page,
    headers,
    column_name,
    client_name,
    project_name,
    anonimize,
  }) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.profillate,
      method: "POST",
      skipRedirect: true,
      body: {
        tableData,
        page,
        headers,
        column_name,
        client_name,
        project_name,
        anonimize,
      },
    });
    return {
      data,
      res,
    };
  }

  async parseColumns() {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.parse,
      method: "GET",
    });
    return {
      data,
      res,
    };
  }

  async parseColumnsDetail(id) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.parse_detail(id),
      method: "GET",
    });
    return {
      data,
      res,
    };
  }

  async creatView(body, reportId) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.create_view(reportId),
      method: "POST",
      skipRedirect: true,
      body,
    });
    return {
      data,
      res,
    };
  }

  async find_project(body) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.find_project,
      method: "POST",
      skipRedirect: true,
      body,
    });
    return {
      data,
      res,
    };
  }

  async parseColumnsV2(body) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.parse_v2,
      method: "POST",
      skipRedirect: true,
      body,
    });
    return {
      data,
      res,
    };
  }

  async check_reuse({ templateData, page, headers }) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.check_reuse,
      method: "POST",
      skipRedirect: true,
      body: {
        templateData,
        page,
        headers,
      },
    });
    return {
      data,
      res,
    };
  }

  async record({ recordData, mode }) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.record,
      method: "POST",
      skipRedirect: true,
      body: {
        recordData,
        mode,
      },
    });
    return {
      data,
      res,
    };
  }

  async grant(data) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.grant,
      method: "POST",
      body: {
        ...data,
      },
    });
    return {
      data,
      res,
    };
  }

  async revoke_access(data) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.revoke_access,
      method: "POST",
      body: {
        ...data,
      },
    });
    return {
      data,
      res,
    };
  }

  async getAccessList(id) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.access_list(id),
      method: "GET",
    });
    return {
      data,
      res,
    };
  }

  async find({ client_id, project_id, with_names }) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.find,
      method: "POST",
      skipRedirect: true,
      body: {
        client_id,
        project_id,
        with_names,
      },
    });
    return {
      data,
      res,
    };
  }

  async to_sql({
    templateData,
    page,
    headers,
    ftype,
    history_entry_id,
    replace_existing,
    anonymized,
    mode,
  }) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.to_sql,
      method: "POST",
      skipRedirect: true,
      body: {
        templateData,
        page,
        headers,
        ftype,
        history_entry_id,
        replace_existing,
        anonymized,
        mode,
      },
    });
    return {
      data,
      res,
    };
  }

  async edit_data({
    id,
    templateData,
    client,
    project,
    before_edited,
    pkl_for_edit,
    custom_columns,
    custom_columns_before,
  }) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.edit_data,
      method: "POST",
      skipRedirect: true,
      body: {
        template_id: id,
        templateData,
        client,
        project,
        before_edited,
        pkl_for_edit,
        custom_columns,
        custom_columns_before,
      },
    });
    return {
      data,
      res,
    };
  }

  async delete_template({ client_name, project_name, pickle_id }) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.delete_template,
      method: "POST",
      skipRedirect: true,
      body: {
        client_name,
        project_name,
        pickle_id,
      },
    });
    return {
      data,
      res,
    };
  }

  async getView(reportId) {
    var { data, res } = await request({
      url: TEMPLATES_ENDPOINTS.get_view(reportId),
      method: "GET",
    });
    return {
      data,
      res,
    };
  }
}

export const TemplatesService = new Templates();
