<template>
  <div class="ann-loader">
    <b-spinner></b-spinner>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.ann-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba($color: #fff, $alpha: 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .spinner-borde {
    margin-left: 0;
  }
}
</style>
