<template>
  <div class="align-template">
    <div class="align-template__selects">
      <v-select
        placeholder="Select DataMorph table"
        @input="onTemplateChange"
        class="add-tag"
        :options="templates"
        :reduce="(temp) => temp.id"
        label="table_name"
        v-model="templateId"
        :disabled="isEditable !== ''"
      >
        <!-- <li slot="list-footer">
          <b-button
            class="btn ml-2 mt-2 small cus-button"
            @click="loadMore"
            :disabled="disableLoadMore"
          >
            <div
              class="btn-loader__wrapper"
              :class="{ 'one-col': !isLoadingMore }"
            >
              <span>Load more</span>
              <CSpinner
                v-if="isLoadingMore"
                color="white"
                style="width: 1rem; height: 1rem"
              />
            </div>
          </b-button>
        </li> -->
      </v-select>
    </div>
    <CCard class="ColumnsView">
      <CRow>
        <CCol><h4 class="font-weight-bold">Template Table</h4></CCol>
        <CCol v-if="showColumn"
          ><h4 class="font-weight-bold">User's Table</h4></CCol
        >
      </CRow>
      <CRow
        v-for="(col, index) in columns"
        class="font-lg d-flex justify-content-between mt-4"
        style="height: 34px"
      >
        <CCol>
          <span>{{ col.name + " (" + col.type + ")" }}</span></CCol
        >
        <CCol v-if="showColumn">
          <v-select
            placeholder="Select column"
            :key="col.name"
            :options="columnsTemplate"
            class="select"
            @input="(e) => changeReference(col.name, e)"
            v-model="selectedCol[index]"
          />
        </CCol>
      </CRow>
    </CCard>
    <div class="align-template__footer">
      <b-button
        class="btn mt-3 cus-button cuz-button--white"
        @click="$emit('close')"
      >
        Cancel
      </b-button>
      <b-button
        class="btn cus-button mt-3"
        :disabled="isLoading || !isDisabledBtn"
        @click="onSave"
      >
        <div class="btn-loader__wrapper" :class="{ 'one-col': !isLoading }">
          <span>Save</span>
          <CSpinner
            v-if="isLoading"
            color="white"
            style="width: 1.5rem; height: 1.5rem"
          />
        </div>
      </b-button>
    </div>
  </div>
</template>
<script>
import { TemplatesService } from "@/services/templates";
import "vue-select/dist/vue-select.css";
export default {
  name: "AlignTemplateNew",
  props: {
    report: Object,
    isEditable: String,
  },

  data() {
    return {
      columns: [],
      columnsTemplate: [],
      templates: [],
      templateId: null,
      arrows: [],
      checkedArrows: [],
      start: undefined,
      end: undefined,
      points: {
        start: undefined,
        end: undefined,
      },
      isLoading: false,
      connectedColumns: {},
      isAllRightColumnsConnected: false,
      referenceFields: {},
      showColumn: false,
      // isDisabledBtn: true,
      column: null,
      allColumns: [],
      selectedCol: new Array(this.columns?.length).fill(null),
      page: 1,
      page_size: 100000,
      total: 0,
      isLoadingMore: false,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    if (this.isEditable) {
      this.getView(this.isEditable);
      this.showColumn = true;
    }
  },
  watch: {
    report: {
      handler() {
        this.columns = this.report.table_data.columns;
      },
      immediate: true,
    },
  },
  computed: {
    isDisabledBtn() {
      const isBtnDisabled =
        Object.keys(this.referenceFields).length ===
        this.report.table_data.columns.length;
      return isBtnDisabled;
    },
    disableLoadMore() {
      return (
        Math.ceil(this.total / this.page_size) <= this.page ||
        this.isLoadingMore
      );
    },
  },

  methods: {
    loadMore() {
      this.page = this.page + 1;
      this.getData();
    },
    async getData() {
      try {
        this.isLoadingMore = true;
        const { data } = await TemplatesService.parseColumnsV2({
          page: this.page,
          limit: this.page_size,
        });
        this.templates = [...this.templates, ...data.data];
        this.total = data.total;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingMore = false;
      }
    },
    async getView(id) {
      try {
        const { data } = await TemplatesService.getView(id);
        const view = data.data;
        this.templateId = view.template;

        this.columnsTemplate = view.columns.map((col) => {
          return `${col.name} (${col.type})`;
        });
        this.referenceFields = view.referrals;
        this.allColumns = view.columns;
        this.selectedCol = new Array(this.columns.length).fill(null);

        this.columns.forEach((column, i) => {
          if (view.referrals.hasOwnProperty(column.name)) {
            const referralValue = view.referrals[column.name];
            const index = this.columnsTemplate.findIndex((template) =>
              template.includes(referralValue)
            );
            if (index !== -1) {
              this.selectedCol[i] = this.columnsTemplate[index];
            }
          }
        });

        const referrals = Object.values(view.referrals);
        this.columnsTemplate = view.columns
          .filter((col) => {
            return !referrals.includes(col.name);
          })
          .map((col) => {
            return `${col.name} (${col.type})`;
          });
      } catch (err) {
        console.log(err);
      }
    },
    onSave() {
      this.isLoading = true;
      const data = {
        template_id: this.templateId,
        reference_fields: this.referenceFields,
      };
      TemplatesService.creatView(data, this.report._id)
        .then(({ data }) => {
          const trimmedData = data.conn;
          // const trimmedData = data.conn?.toString().replace(/\s+/g, "").trim();
          this.$emit("message", trimmedData);
          this.$emit("close");
          this.$emit("fetch");
        })
        .catch((err) => {
          window.alert(err.message || "Something went wrong!", {
            type: "error",
            position: "bottom-right",
          });
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onTemplateChange(e) {
      this.templateId = e;
      this.showColumn = true;
      this.columnsTemplate = [];

      TemplatesService.parseColumnsDetail(this.templateId)
        .then(({ data }) => {
          const temp = data.data;
          this.columnsTemplate = temp.columns.map((item) => {
            return `${item.name} (${item.type})`;
          });
          this.referenceFields = {};
          (this.selectedCol = new Array(this.columns?.length).fill(null)),
            (this.allColumns = temp.columns);
        })
        .catch((err) => {
          console.log(err);
          alert(err, { type: "error", position: "bottom-right" });
        });
    },
    changeReference(data, ref) {
      if (ref === null) {
        this.$delete(this.referenceFields, data);
      } else {
        const cleanRef = ref?.replace(/\s*\([^)]*\)\s*$/, "");
        this.$set(this.referenceFields, data, cleanRef);
      }

      const referenceFieldValues = Object.values(this.referenceFields);
      this.columnsTemplate = this.allColumns
        .filter((col) => !referenceFieldValues.includes(col.name))
        .map((col) => {
          return `${col.name} (${col.type})`;
        });
    },
    onSelectClear(e) {
      console.log("e");
    },
  },
};
</script>
<style scoped lang="scss">
.ml-2 {
  margin-left: 2rem;
}
.load-more-btn {
  border: none;
  background: inherit;
  margin-left: 1rem;
}
.align-template {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ColumnsView {
    flex: 1;
    padding: 15px;
  }

  &__selects {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    .v-select {
      flex: 1;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
}

.select {
  .vs_selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
