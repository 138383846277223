<template>
  <div class="sr-loader">
    <vue-skeleton-loader
      type="rect"
      rounded
      :width="330"
      :height="440"
      animation="fade"
    />
    <vue-skeleton-loader
      class="sr-loader__right"
      type="rect"
      rounded
      :width="'100%'"
      :height="'100%'"
      animation="fade"
    />
  </div>
</template>
<script>
export default {
  name: "BiSingleReportLoader",
};
</script>
<style lang="scss" scoped>
.sr-loader {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
  height: 70vh;
}
</style>
