<template>
  <CModal
    :show.sync="isModalShow"
    :closeOnBackdrop="false"
    @blur="closeCustomReportModal"
    @close="closeCustomReportModal"
    width="50%"
    alignment="center"
  >
    <template slot="header">
      <h4>Request for custom report</h4>
      <svg
        @click="$emit('close')"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        class="bi bi-x"
        viewBox="0 0 16 16"
      >
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
        />
      </svg>
    </template>
    <b-form-group label="Title" label-for="title" description="">
      <b-form-input
        id="title"
        v-model="report.title"
        type="text"
        placeholder="Enter title"
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Description" label-for="input-2">
      <b-form-textarea
        id="input-2"
        v-model="report.message"
        placeholder="Enter description..."
        rows="4"
        max-rows="4"
      ></b-form-textarea>
    </b-form-group>

    <template slot="footer" class="report-create__footer">
      <b-button
        class="btn mt-3 cus-button cuz-button--white"
        @click="$emit('close')"
      >
        Cancel
      </b-button>
      <b-button
        class="btn cus-button mt-3"
        @click="onSave"
        :disabled="isDisabled"
      >
        Send
      </b-button>
    </template>
  </CModal>
</template>
<script>
import { ReportService } from "@/services/reports";
import Loader from "@/components/Loader.vue";

export default {
  name: "CustomReportModal",
  components: { Loader },
  props: {
    isCustomReport: Boolean,
  },
  data() {
    return {
      isLoading: false,
      report: {},
      isDisabled: true,
      isModalShow: false,
    };
  },
  methods: {
    updateIsDisabled() {
      this.isDisabled = this.report.message === "";
    },
    closeCustomReportModal() {
      this.$emit("close");
      this.report.title = "";
      this.report.message = "";
    },
    async onSave() {
      try {
        this.isLoading = true;
        const message = {
          title: this.report.title,
          type: "custom_report",
          message: `${
            this.$route.params.id
              ? `Report id: ${this.$route.params.id}, Description: ${this.report.message}`
              : `Description: ${this.report.message}`
          }`,
        };
        const res = await ReportService.createCustomReport(message);

        this.isLoading = false;
        this.$emit("close");
        this.report.title = "";
        this.report.message = "";
      } catch (err) {
        console.log(err.message);
        window.alert(err.message || "Something went wrong!", {
          type: "error",
          position: "bottom-right",
        });
      }
    },
  },
  watch: {
    isCustomReport: {
      immediate: true,
      handler(val) {
        this.isModalShow = val;
      },
    },
    isModalShow(val) {
      if (!val) this.$emit("close");
    },
      "report.message": "updateIsDisabled",
  },
};
</script>
<style lang="scss" scoped>
.report-create {
  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}

::v-deep .vs__dropdown-toggle {
  border-radius: 10px;
  border: 1px solid #e7edfb;
  background: #fff;
  height: 40px;
}

::v-deep .vs__search::placeholder {
  color: rgba($color: #768192, $alpha: 0.8);
}
.add-tag {
  ::v-deep .vs__search {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #768192;
    border-radius: 0;
  }

  ::v-deep .vs__dropdown-toggle {
    border-radius: 0;
    height: 35px;
  }
}
</style>
