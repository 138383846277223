<template>
  <div class="single-report relative">
    <button @click="goBack" class="goback-btn">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-arrow-left-short"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
        />
      </svg>
      <span>Go back</span>
    </button>
    <div class="d-flex align-items-center justify-content-between">
      <h3 class="page-title">{{ report.name }}</h3>

      <button
        class="bi-button"
        style="margin-bottom: 30px"
        @click="isCustomReport = true"
      >
        Send request for custom report
      </button>
    </div>

    <div v-if="!isLoading">
      <div class="single-report__body">
        <CCard class="single-report__left">
          <CCardBody class="Scrollcard card-after-upload">
            <div class="single-report__img">
              <img :src="report.image" />
            </div>
            <!--            <div class="single-report__title">-->
            <!--              {{ report.name }}-->
            <!--            </div>-->
            <pre class="single-report__text">{{ report.description }}</pre>
            <div class="single-report__app">
              <button v-for="tag in report.tags">{{ tag }}</button>
            </div>
            <div class="single-report__list--title">
              List of required columns:
            </div>
            <ul class="single-report__list" v-if="report.table_data">
              <li v-for="column in report.table_data.columns">
                {{ column.name + " (" + column.type + ")" }}
              </li>
            </ul>
            <div class="single-report__list--title">List of connections:</div>
            <ul class="single-report__list" style="list-style-type: disc">
              <li>
                <div class="d-flex justify-content-between">
                  <span>{{ report.table_data?.table_name }}</span>
                  <div class="d-flex gap-4 mr-4">
                    <img
                      src="/img/copy.svg"
                      class="copy-conn s-report__icon"
                      style="cursor: pointer"
                      @click="copyToClipboard(report.table_data?.conn)"
                    />
                  </div>
                </div>
              </li>
              <li v-for="connection in report.views">
                <div class="d-flex justify-content-between">
                  <span>{{ connection.title }}</span>
                  <div style="display: flex">
                    <img
                      class="s-report__icon"
                      src="/img/edit.svg"
                      style="cursor: pointer; margin: 0 10px"
                      @click="editConnection(connection._id)"
                    />
                    <div class="d-flex gap-4 mr-4">
                      <img
                        src="/img/copy.svg"
                        class="copy-conn s-report__icon"
                        style="cursor: pointer"
                        @click="copyToClipboard(connection.conn)"
                      />
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <div class="single-report__btns">
              <button class="single-report__btn" @click="onAlignTemplate">
                Connect your data
              </button>
              <button
                class="single-report__btn"
                :disabled="isDownloading"
                @click="onDownload"
              >
                Get PBIT file
              </button>
            </div>
          </CCardBody>
        </CCard>
        <div class="single-report__right">
          <CCard class="single-report__chart" style="padding-top: 20px">
            <CCardBody
              v-if="linkIncorrect"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div>
                <h4>Link is not provided / Provided link is incorrect</h4>
              </div>
            </CCardBody>

            <CCardBody v-else class="single-report__chart-content">
              <iframe
                title="Report Section"
                width="100%"
                height="100%"
                :src="report.url"
                frameborder="0"
                allowFullScreen="true"
              ></iframe>
            </CCardBody>
          </CCard>
        </div>
      </div>
    </div>

    <SingleReportLoader v-else />
    <CModal :show.sync="isShowMessage" :closeOnBackdrop="false" width="40%">
      <template slot="header">
        <h4>Dataset is connected</h4>
        <svg
          @click="isShowMessage = false"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </template>
      <div class="modal-body">
        <h4>
          Dataset is connected, please use following connection string as a
          parameter in .pbit file:
        </h4>
        <h4 class="italic-text">{{ messageName }}</h4>
      </div>
      <template slot="footer">
        <b-button
          class="btn mt-3 cus-button cuz-button--white"
          @click="isShowMessage = false"
        >
          Close
        </b-button>
      </template>
    </CModal>

    <CModal
      :show.sync="isOpen"
      :closeOnBackdrop="false"
      @blur="isOpen = false"
      @close="isOpen = false"
      @show="isOpen = false"
      width="60%"
      height="95%"
      alignment="center"
    >
      <template slot="header">
        <h4>Connect your data</h4>
        <svg
          @click="isOpen = false"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </template>
      <AlignTemplate
        v-if="isOpen"
        :report="report"
        @message="onShowMessage"
        @close="isOpen = false"
        @fetch="getReport"
        :is-editable="editId"
      />
      <template slot="footer-wrapper">{{ null }}</template>
    </CModal>

    <CustomReportModal
      :isCustomReport="isCustomReport"
      @close="isCustomReport = false"
    />
  </div>
</template>
<script>
import Clipboard from "clipboard";
import { ReportService } from "@/services/reports";
import { FilesService } from "@/services/files";
import SingleReportLoader from "./components/SingleReportLoader.vue";
import CustomReportModal from "@/views/bi-marketplace/components/CustomReportModal.vue";
import { AnonymizeService } from "@/services/anonymize";
import AlignTemplate from "@/views/single-report/components/AlignTemplate.vue";
import { locale } from "moment";
export default {
  name: "SingleReport",
  components: {
    AlignTemplate,
    CustomReportModal,
    SingleReportLoader,
  },
  data() {
    return {
      report: {},
      isOpen: false,
      isDownloading: false,
      isLoading: false,
      isShowMessage: false,
      messageName: "",
      isCustomReport: false,
      clickedGoBack: false,
      editId: "",
      linkIncorrect: false,
    };
  },
  created() {
    this.getReport();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.clickedGoBack) localStorage.removeItem("modalState");
    next();
  },
  methods: {
    goBack() {
      this.clickedGoBack = true;
      window.history.back();
    },
    copyToClipboard(text) {
      const clipboard = new Clipboard(".copy-conn", {
        text: () => text,
      });

      clipboard.on("success", (e) => {
        e.clearSelection();
        clipboard.destroy();
        this.$toasted.info("Connection string copied!", {
          position: "top-right",
          duration: 5000,
        });
      });

      clipboard.on("error", () => {
        clipboard.destroy();
        this.$toasted.error("Unable to copy text to clipboard.", {
          position: "top-right",
          duration: 5000,
        });
      });

      clipboard.onClick();
    },
    async onCopy(val = false, client_name, project_name) {
      try {
        const { data } = await AnonymizeService.copy(
          val,
          client_name,
          project_name
        );
        await navigator.clipboard.writeText(data.conn);
        this.$toasted.info("Connection string copied!", {
          position: "top-right",
          duration: 5000,
        });
      } catch (e) {
        console.log(e);
      }
    },
    getReport() {
      this.isLoading = true;
      ReportService.getById(this.$route.params.id)
        .then((res) => {
          this.report = res.data.data;
          if (!res.data?.data?.url?.startsWith("https://app.powerbi")) {
            this.linkIncorrect = true;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onAlignTemplate() {
      this.isOpen = true;
      this.editId = "";
    },
    onShowMessage(m) {
      this.isShowMessage = true;
      this.messageName = m;
    },
    onDownload() {
      this.isDownloading = true;
      FilesService.downloadFile(this.report.power_bi_file_id)
        .then((res) => res.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = this.report.power_bi_file_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.isDownloading = false;
        });
    },
    editConnection(id) {
      this.editId = id;
      this.isOpen = true;
    },
  },
};
</script>
<style lang="scss">
.s-report__icon {
  width: 16px;
}
.single-report {
  &__img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    //border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__body {
    display: flex;
    gap: 20px;
  }

  &__title {
    color: #0c2044;
    font-family: Mulish;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    margin: 20px 0 15px;
  }

  &__text {
    color: #0c2044;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    white-space: break-spaces;
  }

  &__app {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin: 15px 0;
    color: #0c2044;
    font-family: Mulish;
    font-size: 12px;
    line-height: normal;
    button {
      border: none;
      padding: 5px 10px;
      background-color: #274b8e;
      color: white;
      border-radius: 5px;
    }
  }

  &__list {
    color: #0c2044;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-left: 20px;
    list-style-type: disc;

    &--title {
      color: #0c2044;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__btn {
    min-width: 105px;
    white-space: nowrap;
    height: 35px;
    background: linear-gradient(95.82deg, #274b8e 4.13%, #389cd9 121.03%);
    border-radius: 10px;
    border: 0;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    padding: 0 12px;

    &:hover,
    &:target,
    &:disabled,
    &:focus,
    &:active {
      color: #fff !important;
    }
  }

  &__left {
    //height: 450px;
    width: 340px;
  }

  &__right {
    flex: 1;
  }

  &__chart {
    width: 100%;
    min-height: 72vh;
    height: calc(100% - 1.5rem);
    object-fit: contain;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
.goback-btn {
  display: flex;
  align-items: center;
  //gap:5px;
  background: linear-gradient(95.82deg, #274b8e 4.13%, #389cd9 121.03%);
  padding: 6px;
  color: white;
  border: none;
  border-radius: 9px;
}
.italic-text {
  font-style: italic;
}
</style>
