import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";
import { ActiveUser } from "@/services/user";
import store from "../store";

export const ANON_ENDPOINTS = {
  process: `${BASE_URL}api/anonymize/process`,
  request: `${BASE_URL}api/anonymize/request`,
  copy: (val, client_name, project_name) => `${BASE_URL}api/anonymize/connection_string?real=${val}&client_name=${client_name}&project_name=${project_name}`,
};

class Anonymize {
  async process(body) {
    var { data, res } = await request({
      url: ANON_ENDPOINTS.process,
      method: "POST",
      skipRedirect: true,
      body: {
        email: ActiveUser.get()?.email.toLowerCase(),
        ...body,
      },
    });
    return {
      data,
      res,
    };
  }

  async request({ project_name, client_name, comment }) {
    var { data, res } = await request({
      url: ANON_ENDPOINTS.request,
      method: "POST",
      skipRedirect: true,
      body: {
        project_name,
        client_name,
        comment,
        email: ActiveUser.get()?.email.toLowerCase(),
      },
    });
    return {
      data,
      res,
    };
  }

  async copy(val, client_name, project_name) {
    var { data, res } = await request({
      url: ANON_ENDPOINTS.copy(val, client_name, project_name),
      method: "GET",
    });
    return {
      data,
      res,
    };
  }
}

export const AnonymizeService = new Anonymize();
